import { configureStore } from '@reduxjs/toolkit';

import { statusError } from './status/status-actions';
import { createActionListenerMiddleware } from './utils/action-listen-middleware';
import account from './account/account-reducer';
import brands from './brands/brands-reducer';
import brandUser from './brand-user/brand-user-reducer';
import cards from './cards/cards-reducer';
import filters from './filters/filters-reducer';
import live from './live/live-reducer';
import locations from './locations/locations-reducer';
import moderator from './moderator/moderator-reducer';
import offer from './offers/current-offer/current-offer-reducer';
import offers from './offers/offers-reducer';
import programs from './programs/programs-reducer';
import status from './status/status-reducer';
import transactions from './transactions/transactions-reducer';
import user from './user/user-reducer';
import users from './users/users-reducer';

const actionListeners = [statusError];
const actionListenerMiddleware =
  createActionListenerMiddleware(actionListeners);
const inTestRun = typeof jest !== 'undefined';

export const store = configureStore({
  reducer: {
    account,
    brands,
    brandUser,
    cards,
    filters,
    offers,
    offer,
    live,
    locations,
    moderator,
    programs,
    status,
    transactions,
    user,
    users,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: inTestRun,
      immutableCheck: inTestRun,
    }).concat(actionListenerMiddleware),
});

export type State = ReturnType<typeof store.getState>;
export type Dispatch = typeof store.dispatch;
