import { Amplify, Auth } from 'aws-amplify';
import { cognito } from './config';

// eslint-disable-next-line consistent-return
export async function currentAuthSession(signOut?: () => void) {
  try {
    const session = await Auth.currentSession();
    if (session) {
      return session;
    }
  } catch (error) {
    if (signOut) return signOut();
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export function bootstrapCognito() {
  Amplify.configure({
    Auth: {
      region: 'eu-west-1',
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      userPoolId: cognito.POOL_ID,
      userPoolWebClientId: cognito.CLIENT_ID,
    },
  });
}
