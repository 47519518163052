import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Layout as AntdLayout } from 'antd';

import Alert from './components/alert';
import { breakpoints } from './theme';

interface LayoutProps {
  side: React.ReactNode;
  main: React.ReactNode;
}

const width = '260';

const topPadding = 30;

const { Sider: AntdSider, Content: AntdContent } = AntdLayout;

const Layout = ({ side, main }: LayoutProps) => {
  const [collapsed, setCollapsed] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (window.innerWidth < breakpoints.sidebarCollapse) setCollapsed(true);
  }, [pathname]);

  return (
    <AntdLayout style={{ height: '100vh' }}>
      <Alert />
      <AntdLayout>
        <Sider
          theme="light"
          breakpoint="md"
          width={width}
          collapsedWidth="0"
          collapsed={collapsed}
          onCollapse={setCollapsed}
        >
          {side}
        </Sider>
        <AntdLayout style={{ zIndex: 1 }}>
          <Content>{main}</Content>
        </AntdLayout>
      </AntdLayout>
    </AntdLayout>
  );
};

const Sider = styled(AntdSider)`
  @media (max-width: 767px) {
    position: fixed;
    z-index: 2;
    height: 100%;
    box-shadow: 0 0 8px 0 rgba(66, 66, 66, 0.15);
  }
`;

const Content = styled(AntdContent)`
  padding: ${topPadding}px 20px 0px;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @media (min-width: ${breakpoints.tabletLandscape}px) {
    padding: ${topPadding}px 40px 0;
  }
`;

export default Layout;
