import React, { createContext, useState, ReactNode, useEffect } from 'react';
import { currentAuthSession } from '../auth';
import { cognito } from '../config';
import http from '../services/http-service';
import useIsSignedIn from './use-is-signed-in';

interface AuthContextType {
  loading: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { signOut } = useIsSignedIn();

  useEffect(() => {
    setLoading(true);

    http.addRequestInterceptor(async req => {
      const authSession = await currentAuthSession(signOut);

      if (authSession && !req.url?.includes(cognito.DOMAIN_URL)) {
        (req.headers as any).Authorization = `Bearer ${authSession
          .getIdToken()
          .getJwtToken()}`;
      }

      return req;
    });
    setLoading(false);
  }, [setLoading, signOut]);

  return (
    <AuthContext.Provider
      value={{
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
