import React from 'react';

import { Trans } from 'react-i18next';
import { GoogleRecaptchaDisclaimerWrapper } from './styled/googleRecaptchaDisclaimer';
import Link from '../../components/link';

export default function GoogleRecaptchaDisclaimer() {
  return (
    <GoogleRecaptchaDisclaimerWrapper>
      <Trans
        ns="common"
        i18nKey="form.captcha"
        components={[
          <Link
            data-testid="privacy-link"
            to="https://policies.google.com/privacy"
          />,
          <Link
            data-testid="terms-link"
            to="https://policies.google.com/terms"
          />,
        ]}
      />
    </GoogleRecaptchaDisclaimerWrapper>
  );
}
