import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, useParams } from 'react-router-dom';
import { shallowEqual } from 'react-redux';

import memoNoProps from '../../utils/memo-no-props';
import Profile from './components/profile';
import Plan from './components/Plan';
import AccountInfo from './components/account-info';
import Billing from './components/billing';
import Contract from './components/contract';
import Users from './components/Users';
import Security from './components/Security';
import HorizontalMenu from '../../components/horizontal-menu';
import { Container, FixedSection, ScrollingContainer } from './styled';
import useIsSignedIn from '../../hooks/use-is-signed-in';
import { useAppSelector } from '../../store/hooks';
import useTransactionDistroOnly from '../../hooks/use-transaction-distro-only';

type TabPath = 'profile' | 'plan' | 'billing' | 'users' | 'security' | 'admin';

interface Tab {
  path: TabPath;
  component: React.ReactElement;
  condition: boolean;
}

const Account = () => {
  const { t } = useTranslation();
  const { accountDetails, hasMarketplaceAccess } = useAppSelector(
    state => ({
      accountDetails: state.account.details,
      hasMarketplaceAccess: state.account.marketplace.hasMarketplaceAccess,
    }),
    shallowEqual,
  );
  const scrollRef = useRef<HTMLDivElement>(null);
  const { isAccountAdmin, isModerator } = useIsSignedIn();
  const { section } = useParams<{ section: TabPath }>();
  const { liveAgreement } = accountDetails || {};
  const { hasAccountTransactionDistroOnly } = useTransactionDistroOnly();

  const tabs: Tab[] = [
    {
      path: 'profile',
      component: <Profile />,
      condition: hasMarketplaceAccess,
    },
    {
      path: 'plan',
      component: <Plan />,
      condition: true,
    },
    {
      path: 'billing',
      component: <Billing />,
      condition: !hasAccountTransactionDistroOnly && isAccountAdmin,
    },
    {
      path: 'users',
      component: <Users />,
      condition: true,
    },
    {
      path: 'security',
      component: <Security />,
      condition: true,
    },
    {
      path: 'admin',
      component: <Contract />,
      condition: isModerator && !!liveAgreement,
    },
  ];
  const visibleTabs = tabs.filter(({ condition }) => condition);

  const activeTab = tabs.find(({ path }) => path === section);

  if (!activeTab) {
    return <Redirect to={`/account/${visibleTabs[0].path}`} />;
  }

  function resetScroll() {
    /* eslint-disable no-unused-expressions */
    scrollRef.current?.scrollTo?.({
      top: 0,
    });
  }

  return (
    <ScrollingContainer ref={scrollRef}>
      <Container>
        <FixedSection>
          <AccountInfo />

          <HorizontalMenu mode="horizontal" selectedKeys={[activeTab.path]}>
            {visibleTabs.map(({ path }) => (
              <HorizontalMenu.Item key={path}>
                <Link to={`/account/${path}`} onClick={resetScroll}>
                  {t(`account.sections.${path}`)}
                </Link>
              </HorizontalMenu.Item>
            ))}
          </HorizontalMenu>
        </FixedSection>

        <div>{activeTab.component}</div>
      </Container>
    </ScrollingContainer>
  );
};

export default memoNoProps(Account);
