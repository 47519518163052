import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ApiKeys,
  BillingConfigurations,
  AccountDetails,
  transformToApiKeys,
  Invoice,
  PricingTemplate,
  AccountProfile,
  ContentProviderInfo,
} from './account-model';
import { actionToError } from '../utils/transform';
import { CountryCode } from '../../types';

export interface AccountState {
  error?: any;
  loading: boolean;
  apiKeys?: ApiKeys;
  invoices: {
    entities?: Invoice[];
    loading: boolean;
    last?: Invoice;
  };
  version?: any;
  details?: AccountDetails;
  billingConfigurations: {
    entity?: BillingConfigurations;
    loading: boolean;
    updating: boolean;
  };
  pricingTemplate: {
    entity?: PricingTemplate;
    loading: boolean;
  };
  marketplace: {
    hasMarketplaceAccess: boolean;
    countryCodes: CountryCode[];
    suppliers: string[];
  };
  contentProviderInfo: ContentProviderInfo | undefined;
  images: {
    updating: boolean;
    entities: {
      squared: string | undefined;
      rounded: string | undefined;
    };
  };
  profile: {
    loading: boolean;
    updating: boolean;
    entity: AccountProfile;
  };
}

export const initialState: AccountState = {
  loading: false,
  billingConfigurations: {
    loading: false,
    updating: false,
  },
  invoices: {
    loading: false,
  },
  pricingTemplate: {
    loading: false,
  },
  marketplace: {
    hasMarketplaceAccess: false,
    countryCodes: [],
    suppliers: [],
  },
  contentProviderInfo: undefined,
  images: {
    updating: false,
    entities: {
      squared: undefined,
      rounded: undefined,
    },
  },
  profile: {
    loading: false,
    updating: false,
    entity: {
      pitch: undefined,
      websiteUrl: undefined,
      androidAppUrl: undefined,
      iosAppUrl: undefined,
      audience: undefined,
      audienceSize: undefined,
      growthForecast: undefined,
    },
  },
};

type AccountPayload<T extends keyof AccountState> = PayloadAction<
  AccountState[T]
>;

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    clear: () => initialState,
    getAccount(state) {
      state.loading = true;
    },
    getAccountSuccess(state, action: AccountPayload<'details'>) {
      state.loading = false;
      state.details = action.payload;
    },
    getAccountError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },

    updateAccount(state) {
      state.loading = true;
    },
    updateAccountSuccess(state, action: AccountPayload<'details'>) {
      state.loading = false;
      state.details = action.payload;
    },
    updateAccountError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },

    toggleAccountLock(state) {
      state.loading = true;
    },
    toggleAccountLockSuccess(state) {
      state.loading = false;
    },
    toggleAccountLockError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },

    getVersion(state) {
      state.loading = true;
    },
    getVersionSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.version = action.payload;
    },
    getVersionError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },

    getApiKeys(state) {
      state.loading = true;
    },
    getApiKeysSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.apiKeys = transformToApiKeys(action.payload);
    },
    getApiKeysError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },

    sendGoLiveRequest(state) {
      state.loading = true;
      state.error = undefined;
    },
    sendGoLiveRequestSuccess(state, action: AccountPayload<'details'>) {
      state.loading = false;
      state.details = action.payload;
    },
    sendGoLiveRequestError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },

    updateAccountSecurity(state) {
      state.loading = true;
      state.error = undefined;
    },
    updateAccountSecuritySuccess(state, action: AccountPayload<'details'>) {
      state.loading = false;
      state.details = action.payload;
    },
    updateAccountSecurityError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = actionToError(action);
    },

    getInvoices(state) {
      state.invoices.loading = true;
    },
    getInvoicesSuccess(state, action: PayloadAction<any>) {
      state.invoices.loading = false;
      state.invoices.entities = action.payload.items;
    },
    getInvoicesError(state, action: PayloadAction<any>) {
      state.invoices.loading = false;
      state.error = actionToError(action);
    },

    updateApiVersion(state) {
      state.loading = true;
    },
    updateApiVersionSuccess(state, action: AccountPayload<'details'>) {
      state.loading = false;
      state.details = action.payload;
    },
    updateApiVersionError(state) {
      state.loading = false;
    },

    getLastInvoice(state) {
      state.invoices.loading = true;
    },
    getLastInvoiceSuccess(state, action: PayloadAction<Invoice>) {
      state.invoices.loading = false;
      state.invoices.last = action.payload;
    },
    getLastInvoiceError(state, action: PayloadAction<any>) {
      state.invoices.loading = false;
      state.error = actionToError(action);
    },

    updateBillingConfigurations(state) {
      state.error = undefined;
      state.billingConfigurations.updating = true;
    },
    updateBillingConfigurationsSuccess(
      state,
      action: PayloadAction<BillingConfigurations>,
    ) {
      state.billingConfigurations.updating = false;
      state.billingConfigurations.entity = action.payload;
    },
    updateBillingConfigurationsError(state, action: PayloadAction<any>) {
      state.billingConfigurations.updating = false;
      state.error = actionToError(action);
    },

    getPricingTemplate(state) {
      state.pricingTemplate.loading = true;
      state.error = undefined;
    },
    getPricingTemplateSuccess(state, action: PayloadAction<PricingTemplate>) {
      state.pricingTemplate.loading = false;
      state.pricingTemplate.entity = action.payload;
    },
    getPricingTemplateError(state, action: PayloadAction<any>) {
      state.pricingTemplate.loading = false;
      state.error = actionToError(action);
    },

    checkMarketplaceAccess(state) {
      state.loading = true;
    },
    checkMarketplaceAccessSuccess(
      state,
      action: AccountPayload<'marketplace'>,
    ) {
      state.loading = false;
      state.marketplace = { ...state.marketplace, ...action.payload };
    },
    checkMarketplaceAccessError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    checkIsContentProvider(state) {
      state.loading = true;
    },
    checkIsContentProviderSuccess(
      state,
      action: AccountPayload<'contentProviderInfo'>,
    ) {
      state.loading = false;
      state.contentProviderInfo = action.payload || undefined;
    },
    checkIsContentProviderError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    updateImages(state) {
      state.images.updating = true;
    },
    updateImagesSuccess(state, action: AccountPayload<'details'>) {
      state.images.updating = false;
      state.images.entities = {
        squared: action.payload?.imagePath,
        rounded: action.payload?.croppedImagePath,
      };
    },
    updateImagesError(state, action: PayloadAction<any>) {
      state.images.updating = false;
      state.error = action.payload;
    },

    getProfile(state) {
      state.profile.loading = true;
    },
    getProfileSuccess(state, action: PayloadAction<AccountProfile>) {
      state.profile.loading = false;
      state.profile.entity = { ...state.profile.entity, ...action.payload };
    },
    getProfileError(state, action: PayloadAction<any>) {
      state.profile.loading = false;
      state.error = action.payload;
    },

    updateProfile(state) {
      state.profile.updating = true;
    },
    updateProfileSuccess(state, action: PayloadAction<AccountProfile>) {
      state.profile.updating = false;
      state.profile.entity = { ...state.profile.entity, ...action.payload };
    },
    updateProfileError(state, action: PayloadAction<any>) {
      state.profile.updating = false;
      state.error = action.payload;
    },
  },
});

export const { actions } = accountSlice;
export default accountSlice.reducer;
