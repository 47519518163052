import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import RawDetailService from '../../store/raw-detail/raw-detail-api-service';
import drawerTransition, { DrawerVisibleProps } from '../drawer-transition';
import Spin from '../spin';
import { RawDetailDrawer } from './styled';
import { ReactComponent as CloseIcon } from '../../assets/x.svg';
import Empty from '../empty';
import { useAppSelector } from '../../store/hooks';

interface RawDetailProps extends DrawerVisibleProps {
  type: string;
  onClose: () => void;
  id?: string;
}

const rawDetailService = new RawDetailService();

const rawDetailTitleKeys = [
  'brands',
  'cards',
  'hooks',
  'locations',
  'offers',
  'programs',
  'transactions',
  'default',
] as const;
type RawDetailTitleKey = typeof rawDetailTitleKeys[number];

function isKey(type: string): type is RawDetailTitleKey {
  return rawDetailTitleKeys.includes(type as RawDetailTitleKey);
}

function typeKey(type: string): RawDetailTitleKey {
  if (type.includes('offer')) return 'offers';

  if (isKey(type)) return type;

  return 'default';
}

const RawDetail = ({ type, id, onClose, visible }: RawDetailProps) => {
  const { t } = useTranslation();
  const [failed, setFailed] = useState(false);
  const [jsonComponent, setJsonComponent] = useState<JSX.Element | null>(null);
  const apiVersion = useAppSelector(state => state.account.details?.apiVersion);

  useEffect(() => {
    if (id) {
      const rawDetailPromise = rawDetailService
        .getDetail(type, id, {
          headers: {
            'fidel-version': apiVersion,
          },
        })
        .then(response => response.data.items[0]);

      const componentPromise = import('./JsonPreview').then(
        module => module.default,
      );

      Promise.all([rawDetailPromise, componentPromise])
        .then(([json, Component]) => {
          if (json) setJsonComponent(<Component body={json} readOnly />);
          else setFailed(true);
        })
        .catch(_ => {
          setFailed(true);
        });
    }

    return () => {
      setJsonComponent(null);
      setFailed(false);
    };
  }, [type, id, apiVersion]);

  return (
    <RawDetailDrawer
      title={t(`raw.details.${typeKey(type)}`)}
      visible={visible}
      onClose={onClose}
      closeIcon={<CloseIcon />}
      destroyOnClose
    >
      <>{jsonComponent || (failed ? <Empty /> : <Spin />)}</>
    </RawDetailDrawer>
  );
};
export default drawerTransition(RawDetail);
