import jwtDecode from 'jwt-decode';

import { AccountStatusTypesEnum } from '@fidel.uk/types/lib/account';
import http from '../../services/http-service';
import { getAllItems } from '../utils/pagination';
import { validateStatus } from '../utils/validate-status';
import { SupportedCountriesType } from '../../utils/countries';
import {
  Config,
  VisaConfig,
  AccountImages,
  AccountProfile,
} from './account-model';
import { ProgramType } from '../programs/programs-reducer';

export interface UpdateAccountSecurityPayload {
  companyName?: string;
  config?: Config;
}

export interface SendGoLiveRequestPayload {
  address: string;
  appName: string;
  name: string;
  legalName: string;
  cardsOnFileRange: string;
  city: string;
  companyNumber?: string;
  countryCode: string;
  faqDocumentUrl?: string;
  description: string;
  monthlyActiveUsersRange: string;
  monthlyTransactionsRange: string;
  pciDssAoCDocumentUrl?: string;
  postcode: string;
  privacyPolicyDocumentUrl: string;
  termsAndConditionsDocumentUrl: string;
  vatNumber?: string;
  poNumber?: string;
  website: string;
  invoiceEmails?: string[];
  programType: 'transaction-stream' | 'transaction-select';
}

export interface UpdateAccountPayload {
  name?: string;
  legalName?: string;
  poNumber?: string;
  territories?: SupportedCountriesType[];
  programType?: ProgramType;
  visaConfig?: VisaConfig;
}

export interface ToggleAccountLockPayload {
  reason?: string;
  status: AccountStatusTypesEnum;
}

export default class AccountApiService {
  public getAccount(tokenOrId: string | undefined) {
    /* When fetching for the first time, getAccount consumes a token.
     * Later, we can simply refetch account info using the accountId. */
    let accountId: string | undefined;
    try {
      const decodedToken: any = jwtDecode(tokenOrId as string);
      accountId = decodedToken?.permission?.accountId;
    } catch (e) {
      /* If it’s not a decodable token, it may be an accountId. */
      accountId = tokenOrId;
    }

    return http.get(`accounts/${accountId}`);
  }

  public updateAccount(
    accountId: string | undefined,
    payload: UpdateAccountPayload,
  ) {
    return http.patch(`accounts/${accountId}`, payload);
  }

  public toggleAccount(
    accountId: string | undefined,
    payload: ToggleAccountLockPayload,
  ) {
    return http.patch(`accounts/${accountId}/status`, payload);
  }

  public sendGoLiveRequest(
    accountId: string | undefined,
    account: SendGoLiveRequestPayload,
  ) {
    return http.post(`accounts/${accountId}/live-requests`, account);
  }

  public updateAccountSecurity(
    accountId: string | undefined,
    payload: UpdateAccountSecurityPayload,
  ) {
    return http.patch(`accounts/${accountId}/security-configurations`, payload);
  }

  public getApiKeys() {
    return http.get('credentials');
  }

  public async getAllInvoices(
    accountId: string | undefined,
    liveAgreement: boolean,
  ) {
    let items = [];
    if (liveAgreement) {
      items = await getAllItems(last => this.getInvoices(accountId, { last }));
    }

    return { data: { items } };
  }

  public getVersions() {
    return http.get('versions', {
      params: { order: 'desc', limit: 1 },
    });
  }

  public updateApiVersion(accountId: string | undefined, version: string) {
    return http.patch(`accounts/${accountId}/version`, {
      version,
    });
  }

  private getInvoices(accountId: string | undefined, { last }: any = {}) {
    let params: any = {};
    if (last) params = { ...params, start: JSON.stringify(last) };

    return http.get(`accounts/${accountId}/invoices`, {
      params,
    });
  }

  public getLastInvoice(accountId: string | undefined) {
    return http.get(`accounts/${accountId}/invoices`, {
      params: { limit: 1 },
    });
  }

  public updateBillingConfigurations(
    accountId: string | undefined,
    billingConfiguration: any,
  ) {
    return http.patch(
      `accounts/${accountId}/billing-configurations`,
      billingConfiguration,
    );
  }

  public getPricingTemplate() {
    return http.get('billing/pricing-templates');
  }

  public checkMarketplaceAccess(accountId: string) {
    return http.get(`accounts/${accountId}/marketplace`, {
      validateStatus,
    });
  }

  public checkIsContentProvider(accountId: string) {
    return http.get(`offers-content-providers/accounts/${accountId}`, {
      validateStatus,
    });
  }

  public updateImages(accountId: string | undefined, images: AccountImages) {
    return http.patch(`accounts/${accountId}/images`, images);
  }

  public getProfile(accountId: string | undefined) {
    return http.get(`accounts/${accountId}/profiles`, { validateStatus });
  }

  public updateProfile(accountId: string | undefined, profile: AccountProfile) {
    return http.patch(`accounts/${accountId}/profiles`, profile);
  }
}
