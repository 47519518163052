import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { actionToError } from '../utils/transform';

export interface StepsCompleted {
  cloWelcomeMessage?: boolean;
  dashboardBrands?: boolean;
  dashboardCards?: boolean;
  dashboardExistingUser?: boolean;
  dashboardLocations?: boolean;
  dashboardOffersMarketplaceAddToProgram?: boolean;
  dashboardOffersMarketplaceRequestsTab?: boolean;
  dashboardOffersMarketplaceSampleOffer?: boolean;
  dashboardOffersMarketplaceWelcome?: boolean;
  dashboardPlayground?: boolean;
  dashboardPrograms?: boolean;
  dashboardTransactions?: boolean;
  dashboardWelcome?: boolean;
  playgroundPopover?: boolean;
  npsFormShown?: string;
  csatFormShown?: string;
}

export interface UserState {
  error?: any;
  loading: boolean;
  details?: {
    id: string;
    nameFirst: string;
    nameLast: string;
    email: string;
    stepsCompleted: StepsCompleted;
  };
}

const initialState: UserState = {
  loading: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clear() {
      return initialState;
    },

    getUser(state) {
      state.loading = true;
      state.error = undefined;
    },

    getUserSuccess(state, action: PayloadAction<any>) {
      const user = action.payload;

      state.loading = false;
      state.details = {
        id: user.id,
        nameFirst: user.nameFirst,
        nameLast: user.nameLast,
        email: user.email,
        stepsCompleted: user.stepsCompleted,
      };
    },

    getUserError(state, action: any) {
      state.loading = false;
      state.error = actionToError(action);
    },
  },
});

export const { actions } = userSlice;
export default userSlice.reducer;
